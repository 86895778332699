import React from "react";

const Arrow = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="122.88px"
      height="80.593px"
      fill="currentColor"

      viewBox="0 0 122.88 80.593"
      enableBackground="new 0 0 122.88 80.593"
    >
      <g>
        <polygon points="122.88,0 122.88,30.82 61.44,80.593 0,30.82 0,0 61.44,49.772 122.88,0" />
      </g>
    </svg>
  );
};
export default Arrow;
