/* eslint-disable jsx-a11y/iframe-has-title */
import { Link, useParams } from "react-router";

import "../App.css";
import "./style.css";
import Dropdown from "./components";
import { useContext, useState } from "react";
import { GalleryContext } from "../context";
import ThinArrow from "../images/thinArrow";

const getTabData = (tabs) => {
  return tabs.find((tab) => {
    return tab.name === "rich_media";
  });
};

const RenderCurrentTabDemoList = ({ section, currentSection }) => {
  const { items = [] } = currentSection || {};

  return (
    <div className="right">
      {items.map((item) => {
        return (
          <div
            key={item.defaultImageURL}
            className="pb-3 flex flex-col items-center cursor-pointer bg-light_orange_ra box"
          >
            <h3 className="capitalize text-center right-text-heading">
              {`${section}  /  ${item.display_name}`}
            </h3>
            <img
              className="border-2 rounded-xl border-slate-200"
              src={item.defaultImageURL}
              alt={item.defaultImageURL}
            />
            <Dropdown
              title="Demos"
              list={item.formats}
              section={section}
              name={item.name}
            />
          </div>
        );
      })}
    </div>
  );
};

const ShowPage = () => {
  const { tabs, isMobile } = useContext(GalleryContext);
  const { sections } = getTabData(tabs);
  const { section, client, format } = useParams();
  const currentSection = sections.find((item) => section === item.name) || {};
  const { items } = currentSection;
  const currentClient = items.find((item) => item.name === client);
  const { formats } = currentClient;
  const currentFormat = formats.find((item) => item.name === format);
  return (
    <div className="page-layout">
      <div className="relative mt-16 flex h-screen justify-center mx-auto bg-no-repeat bg-center overflow-hidden">
        {!isMobile && (
          <Link to={{ pathname: "/" }}>
            <span className="flex items-center back-button fixed left-10 flex justify-center lg:px-5 lg:py-2.5 py-3.5 px-7 bg-dark_orange_ra rounded-xl text-white">
              <ThinArrow /> <span className="ml-3">Home</span>
            </span>
          </Link>
        )}

        <iframe
          src={currentFormat.iframeURL}
          className="border-2 border-slate-200 iframe"
        />
      </div>
      {!isMobile && (
        <RenderCurrentTabDemoList
          section={section}
          currentSection={currentSection}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default ShowPage;
