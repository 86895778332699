import React, { useContext } from "react";
import { useState } from "react";
import CardListContainer from "./CardListContainer";
import RichMedia from "../images/richMedia.js";
import ProgramaticAdIcon from "../images/prgrammatic.js";
import NativeAd from "../images/native.js";
import "./gallery.css";
import { GalleryContext } from "../context.js";

const Galllery = () => {
  const galleryContext = useContext(GalleryContext);
  const { tabs } = galleryContext || {};
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const activeClass = "text-orange-500 border-b-2 border-orange-500 ";
  const commonTabClass =
    "flex-col items-center flex w-1/4 text-center leading-10 cursor-pointer text-xs";

  const renderTabIcon = (tabName) => {
    if (tabName === "rich_media") {
      return <RichMedia />;
    } else if (tabName === "programmatic") {
      return <ProgramaticAdIcon />;
    }
    return <NativeAd />;
  };

  return (
    <div className="relative">
      <div
        id="tabs"
        className="p-2.5 custom-width align-center justify-between flex left-1/2 -translate-x-1/2 bg-white -top-10 absolute mx-auto rounded-xl w-1/2 border"
      >
        {tabs.map((tab, i) => {
          return (
            <div
              key={i}
              className={`${
                currentTab.name === tab.name ? activeClass : "text-orange-200"
              } ${commonTabClass}`}
              onClick={() => setCurrentTab(tab)}
            >
              {/* <span className="icon">{tab.icon}</span> */}
              <div className="tab-icon">{renderTabIcon(tab.name)}</div>
              <div className="leading-10 tab-text">{tab.display_name}</div>
            </div>
          );
        })}
      </div>
      <CardListContainer tabData={currentTab} />
    </div>
  );
};

export default Galllery;
