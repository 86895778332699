import React from "react";
import Card from "./Card";

export default function ProgrammaticAdsContainer({ data }) {
  const { name, list = [], gifAnimation } = data || {};

  return (
    <div className="pt-4 font-semibold rounded-3xl mb-16">
      <>
        <div className="heading-text rounded container mx-auto lg:px-5 py-2 px-0  section_heading sticky z-40 lg:text-3xl text-2xl capitalize">
          {name} <span>ads</span>
        </div>
        <div className="mt-8 flex flex-wrap mx-auto container">
          {list.map((item, i) => {
            return (
              <Card
                key={i}
                cardDetail={item}
                tabName={name}
                gifAnimation={gifAnimation}
              />
            );
          })}
        </div>
      </>
    </div>
  );
}
