import React from "react";

import Galllery from "./components/Galllery";
import "./App.css";

export default function Home() {
  return (
    <div className="text-text_color_ra container-fluid ">
      <div className="bg-light_orange_ra mt-16 text-center pt-12 pb-20">
        <h1 className="brand-title mx-auto lg:w-1/2 lg:text-6xl text-5xl font-semibold text-text_color_ra">
          RediAds Gallery
        </h1>
        <p className="description mt-5 lg:w-1/2 w-3/4 text-xs text-center mx-auto font-normal">
          Showcasing impactful videos, banners, animations, and interactive
          designs.
        </p>
        <p className="description mt-2 lg:w-1/2 w-3/4 text-xs text-center mx-auto font-normal">
          Discover, customize, and create ads that captivate audiences, drive
          engagement, and elevate your brand.
        </p>
      </div>
      <Galllery />
    </div>
  );
}
