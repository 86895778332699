import React, { useContext, useEffect, useState } from "react";

import rediadsLogo from "../images/rediads-logo.svg";
import loginIcon from "../images/login.png";
import { navLinks } from "../constants/NavLinks";
import Arrow from "../images/arrow";
import { GalleryContext } from "../context";
import { useParams } from "react-router";

export default function Header() {
  const galleryContext = useContext(GalleryContext);
  const { isMobile } = galleryContext;
  const params = useParams();
  const isHomePage = Object.keys(params).length === 0;

  const [active, setActive] = useState(false);
  const [currentColorClass, setCurrentColorClass] =
    useState("bg-light_orange_ra");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setCurrentColorClass("bg-white");
      } else {
        setCurrentColorClass("bg-light_orange_ra");
      }
    });
  }, []);

  if (isMobile && !isHomePage) {
    return <></>;
  }

  const getDropDown = (dropdownItems = []) => {
    if (dropdownItems.length) {
      return (
        <ul className="dropdown-menu">
          {dropdownItems.map((item) => {
            return (
              <li key={item.link}>
                <a className="text_color_ra dropdown-item" href={item.link}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const getNavLinks = () => {
    return (
      <nav className="flex items-center flex-1 mob-nav">
        <ul className="flex items-center flex-1 mob-nav-list">
          {navLinks.map((navItem) => {
            const { dropdownItems } = navItem || {};
            return (
              <li
                key={navItem.title}
                className={`${
                  dropdownItems ? "relative" : ""
                } nav-item lg:py-3 lg:px-4`}
              >
                <a
                  className="flex nav-link hover:text-dark_orange_ra text-text_color_ra py-2"
                  href={navItem.link}
                >
                  <span>{navItem.title}</span>
                  {dropdownItems && (
                    <span className="arrow">
                      <Arrow />
                    </span>
                  )}
                </a>
                {getDropDown(navItem.dropdownItems)}
              </li>
            );
          })}
        </ul>
        <a
          href="https://rediads.com/login"
          className="flex justify-center lg:px-5 lg:py-2.5 py-3.5 px-7 bg-dark_orange_ra rounded-xl text-white login-btn"
        >
          <img className="w-4 mr-2" alt="brand_logo" src={loginIcon} />
          Login
        </a>
      </nav>
    );
  };
  return (
    <div className={`${currentColorClass} w-screen z-50 fixed top-0 px-4 py-3`}>
      <header className="mx-auto container">
        <div className="flex items-center">
          <button
            onClick={() => setActive(!active)}
            className={`mt-2 show-mobile nav-bar-icon ${
              active ? "active" : ""
            }`}
            type="button"
          >
            <span></span>
          </button>
          <a className="py-1 lg:p-2" href="https://rediads.com/">
            <span className="company-logo">
              <img className="mr-2" alt="brand_logo" src={rediadsLogo} />
              <span className="antialiased text-text_color_ra"> RediAds</span>
            </span>
          </a>
          {getNavLinks(active)}
        </div>
      </header>
    </div>
  );
}
