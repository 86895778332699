import React from "react";
import Card from "./Card";

export default function RichMediaContainer({ data }) {
  const { sections, name: tabname } = data || {};

  const renderSection = (section) => {
    const { name, items, gifAnimation } = section;
    return (
      <>
        <div className="heading-text rounded container mx-auto lg:px-5 py-2 px-0  section_heading sticky z-40 lg:text-3xl text-2xl capitalize">
          {name} <span>ads</span>
        </div>
        {/* <div className="mt-8 grid lg:grid-cols-4 gap-4 mx-auto container"> */}
        <div className="mt-8 flex flex-wrap mx-auto container">
          {items.map((item) => {
            return (
              <Card
                section={name}
                cardDetail={item}
                tabName={tabname}
                gifAnimation={gifAnimation}
              />
            );
          })}
        </div>
      </>
    );
  };

  return sections.map((section, i) => {
    return (
      <div
        className="pt-4 font-semibold section_container rounded-3xl mb-16"
        key={i}
      >
        {renderSection(section)}
      </div>
    );
  });
}
