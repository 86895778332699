/* eslint-disable jsx-a11y/iframe-has-title */
import { Link, useParams } from "react-router";

import "../App.css";
import "./style.css";
import Dropdown from "./components";
import { useContext } from "react";
import { GalleryContext } from "../context";
import ThinArrow from "../images/thinArrow";

const RenderCurrentTabDemoList = ({ list }) => {
  return (
    <div className="right">
      {list.map((item) => {
        return (
          <div
            key={item.defaultImageURL}
            className="pb-3 flex flex-col items-center cursor-pointer bg-light_orange_ra box"
          >
            <h3 className="capitalize text-center">{`${item.display_name}`}</h3>
            <img src={item.defaultImageURL} alt={item.defaultImageURL} />
            <Dropdown
              title="Show Formats"
              list={item.formats}
              // section={section}
              name={item.name}
            />
          </div>
        );
      })}
    </div>
  );
};
const getTabData = (tabs) => {
  return tabs.find((tab) => {
    return tab.name === "programmatic";
  });
};

const ShowPage = () => {
  const { tabs, isMobile } = useContext(GalleryContext);
  const { list } = getTabData(tabs);
  const { widgetName, format } = useParams();
  const currentWidget = list.find((item) => item.name === widgetName);

  const { formats } = currentWidget;
  const currentFormat = formats.find((item) => item.name === format);
  return (
    <div className="page-layout">
      <div className="relative mt-16 flex h-screen justify-center mx-auto bg-no-repeat bg-center overflow-hidden">
        {!isMobile && (
          <Link to={{ pathname: "/" }}>
            <span className="back-button fixed flex items-center left-10 flex justify-center lg:px-5 lg:py-2.5 py-3.5 px-7 bg-dark_orange_ra rounded-xl text-white">
              <ThinArrow /> <span className="ml-3">Home</span>
            </span>
          </Link>
        )}
        <iframe
          src={currentFormat.iframeURL}
          className="border-2 border-slate-200 iframe"
        />
      </div>
      {!isMobile && <RenderCurrentTabDemoList list={list} />}
    </div>
  );
};

export default ShowPage;
