import React, { useContext } from "react";

import { useState } from "react";
// import sympoloImage from "../images/sompolo.webp";
import "./cardStyle.css";
import { GalleryContext } from "../context";

const Card = ({ section, cardDetail, tabName, gifAnimation }) => {
  const {
    defaultImageURL,
    animatedImageURL,
    display_name,
    name,
    formats = [],
  } = cardDetail || {};
  const { isMobile } = useContext(GalleryContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultImageURL);

  const onClick = () => {
    window.open(getHref(formats[0].name));
  };

  const getHref = (format) => {
    let anchorHref = "";
    if (tabName === "rich_media") {
      anchorHref = `/rich_media_ads/${section}/${name}/${format}`;
    } else if (tabName === "programmatic") {
      anchorHref = `/programmatic_ads/${name}/${format}`;
    }
    return anchorHref;
  };

  return (
    <div className="mb-10 mx-auto flex flex-col items-center card_container">
      <div
        onClick={() => onClick()}
        className="cursor-pointer mb-4 card_title text-lg capitalize text-text_color_ra font-medium"
      >
        {display_name}
      </div>
      <div
        onClick={() => onClick()}
        onMouseEnter={() => setCurrentImage(animatedImageURL)}
        onMouseLeave={() => setCurrentImage(defaultImageURL)}
        className="cursor-pointer aspect-[1/2] ad_image_container rounded-3xl border-2 border-slate-200 overflow-hidden"
      >
        <img
          className="h-full aspect-[1/2]"
          alt="test"
          src={
            gifAnimation === "always" || isMobile
              ? animatedImageURL
              : currentImage
          }
        />
      </div>

      <div
        onClick={() => {
          if (isMobile) {
            setShowDropdown(!showDropdown);
          } else {
            onClick();
          }
        }}
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
        className="relative mt-6 flex justify-center cursor-pointer items-center relative text-white font-medium h-10 demo_dropdown w-44 bg-dark_orange_ra rounded-md"
      >
        Demos
        {showDropdown && (
          <ul className="absolute bg-white top-full rounded w-full text-sm shadow text-text_color_ra">
            {formats.map((item) => {
              return (
                <li className="text-center p-2 hover:bg-slate-100">
                  <a target="_blank" href={getHref(item.name)} rel="noreferrer">
                    {item.display_name}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Card;
