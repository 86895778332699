import React from "react";

import ProgrammaticAdsContainer from "./Programmatic";
import RichMediaContainer from "./RichMedia";

export default function CardListContainer({ tabData }) {
  const { name } = tabData || {};
  const getCurrentTabContentCompoent = () => {
    switch (name) {
      case "rich_media":
        return <RichMediaContainer data={tabData} />;

      case "programmatic":
        return <ProgrammaticAdsContainer data={tabData} />;

      default:
        return <>
        <div className="h-96 flex justify-center items-center text-2xl font-bold">
          <h1>Section is coming soon</h1>
        </div>
        </>;
    }
  };
  return (
    <div className="cards_block w-screen pt-16">
      {getCurrentTabContentCompoent()}
    </div>
  );
}
