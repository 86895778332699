import React, { useContext } from "react";
import rediadsLogo from "../images/rediads-logo.svg";
import { GalleryContext } from "../context";
import { useParams } from "react-router";

export default function Page() {
  const galleryContext = useContext(GalleryContext);
  const { isMobile } = galleryContext;
  const params = useParams();
  const isHomePage = Object.keys(params).length === 0;
  const liData = [
    { link: "https://blog.rediads.com/", text: "Blog" },
    { link: "https://rediads.com/casestudies", text: "Case studies" },
    { link: "https://rediads.com/privacy-policy/", text: "Privacy policy" },
  ];
  if (isMobile && !isHomePage) {
    return <></>;
  }
  return (
    <footer className="footer lg:my-32 my-20 lg:px-0 px-5 container mx-auto">
      <div className="container">
        <div className="grid lg:grid-cols-4 gap-x-20">
          <div className="w-full">
            <a className="flex mb-4" href="index.html">
              <img alt="brand_logo" src={rediadsLogo} />
              <span className="company-logo mx-2">Rediads</span>
            </a>
            <p className="text-sm mb-4 pb-4">
              Dive into the range of services offered by Rediads. From advanced
              ad monetization strategies to analytics and performance reporting,
              we provide a comprehensive suite designed to boost your revenue
              and elevate your publishing platform.{" "}
            </p>
            <div className="w-full/4">
              <a
                className=""
                href="https://www.linkedin.com/company/rediads/"
                target="_blank"
                aria-label="LinkedIn"
              >
                <i className=""></i>
              </a>
            </div>
          </div>
          <div className="w-full">
            <ul className="text-list">
              {liData.map((item) => {
                const { text, link } = item;

                return (
                  <li key={link}>
                    <a className="" href={link}>
                      {text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full">
            <ul className="text-list">
              <li>
                <a className="" href="mailto:support@rediads.com">
                  support@rediads.com
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <h3 className="mb-2 font-bold">Stay up to date</h3>
            <p className="mb-4 text-sm">
              Subscribe to our news and case studies
            </p>
            <div className="subscrib-box">
              <input
                className="form-control"
                type="text"
                placeholder="Your email"
              />
              <button className="btn-subscribe" type="button">
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <p className="footer-bottom">
          <span className="text">© All rights reserved. Made by</span>
          <a
            className="link"
            href="https://rediads.com/"
            target="_blank"
            rel="noopener"
          >
            Rediads
          </a>
        </p>
      </div>
    </footer>
  );
}
